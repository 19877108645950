import React from 'react';
import { Layout } from 'antd';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Header from '../../components/PageLayout/Header';
import SidebarWrapper from '../../components/PageLayout/Sidebar';
import SEO from '../../components/Seo';
import ReactMarkdown from 'react-markdown'

import 'prismjs/themes/prism-solarizedlight.css';
import './highlight-syntax.less';
import style from './post.module.less';

const Post = ({ data }) => {
  const {
    title, cover, excerpt, body, path,
  } = data.allStrapiBlog.edges[0].node;
  return (
    <Layout className="outerPadding">
      <Layout className="container">
        <SEO
          title={title}
          description={excerpt}
          path={path}
          keywords={['Jordon', 'Buchanan', 'Chicago', 'FullStack developer', 'Javascript', 'ReactJS', 'NodeJS', 'Gatsby']}
        />
        <Header />
        <SidebarWrapper>
          <div className="marginTopTitle">
            <h1>{title}</h1>
            <div className={style.bannerImgContainer}>
              <Img className={style.bannerImg} fluid={cover[0].localFile.childImageSharp.fluid} title={excerpt} alt={title} />
            </div>
            <ReactMarkdown className={style.blogArticle}>{body}</ReactMarkdown>
          </div>
        </SidebarWrapper>
      </Layout>
    </Layout>
  );
};
export const pageQuery = graphql`
  query($postPath: String!) {
    allStrapiBlog(filter: { path: { eq: $postPath } }) {
      edges {
        node {
            strapiId
            path
            title
            body
            tags
            excerpt
            cover{
              localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                } 
            }
        }
      }
    }
  }
`;

export default Post;